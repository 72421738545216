import React, { useState } from 'react';

import ButtonCustom from '../UI/buttonCustom';

import './popup-register-now.scss';

const PopupRegisterNow = ({ isShow, onTogglePopup }) => {
	const [isSend, setSend] = useState(false);

	const onClose = () => {
		onTogglePopup();
		setSend(false);
	};

	const onSendForm = async (event) => {
		event.preventDefault();

		const form = event.target;
		const formData = new FormData(form);
		const action = form.action;

		try {
			const response = await fetch(action, {
				mode: 'no-cors',
				method: 'POST',
				body: formData,
			});

			if (response.ok) {
				setSend(true);
				console.log('Form submitted successfully');
			} else {
				setSend(true);
				console.error('Error submitting form');
			}
		} catch (error) {
			console.error('Network error:', error);
		}
	};

	return (
		<>
			<div
				className={`popup-substrate ${isShow ? 'show' : 'hide'}`}
				onClick={onClose}
			></div>

			<section className={`popup-register-now ${isShow ? 'show' : 'hide'}`}>
				<div className='play-btn-block-figure-borders' />

				{isSend ? (
					<div className='form-register-now-sending'>
						<p>THANK YOU FOR SUBSCRIBING</p>
						<p>Check your email to confirm registration</p>
					</div>
				) : (
					<form
						onSubmit={onSendForm}
						className='form-register-now'
						id='sib-form'
						method='POST'
						action='https://1def7879.sibforms.com/serve/MUIFAAwVE83JdpK8lSEvY3vFdV2OhiTl-u1szJF230GuDrgf0fzz4RA4XivNybsHfopdOqRS8uihxGt-rBJCEXRqiMMrBJsG_JjxmR0W5nqxwqGm5ByvwuD0zj-M_1J9-6Ma3WOrfxHvypJWiNugYYaDI8XBisCByGKkw-YFz_lMmv4X8O00uYA56YcN6kwGCmfk8c8jLb2uSquL'
						data-type='subscription'
					>
						<h2 className='form-register-now-title'>EMBARK ON ENDLESS ADVENTURE</h2>

						<input
							className='form-register-now-field'
							maxLength='200'
							type='text'
							id='FIRSTNAME'
							name='FIRSTNAME'
							autoComplete='off'
							placeholder='Your Name'
							data-required='true'
							required
						/>

						<input
							className='form-register-now-field'
							type='text'
							id='EMAIL'
							name='EMAIL'
							autoComplete='off'
							placeholder='Email'
							data-required='true'
							required
						/>

						<ButtonCustom title='register-now' caption='join waitlist' />

						<p className='form-register-now-message'>
							IMPORTANT: To complete the registration, you must confirm your subscription via the email we will send to
							you.
						</p>
					</form>
				)}
			</section>
		</>
	);
};

export default PopupRegisterNow;
