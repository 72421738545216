import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import ButtonCustom from '../UI/buttonCustom';

import './Demo.scss';

export default function Demo({ onTogglePopup }) {
	const responsive = {
		desktop: {
			breakpoint: {
				max: 4000,
				min: 0,
			},
			items: 1,
			partialVisibilityGutter: 30,
		},
	};

	return (
		<div className='demo'>
			<div
				className='demo-anker'
				id='demo'
			/>
			<div className='container'>
				<Carousel
					containerClass='demo-slider'
					responsive={responsive}
					arrows={false}
					autoPlay={true}
					autoPlaySpeed={1}
					customTransition='all 60s linear'
					infinite={true}
					pauseOnHover={false}
					transitionDuration={60000}
				>
					<div className='demo-cloud demo-cloud-1' />
					<div className='demo-cloud demo-cloud-2' />
				</Carousel>

				<div className='demo-box-title'>
					<h1 className='demo-title'>Stay updated</h1>
					<div className='demo-block'>
						<ButtonCustom
							title='register-now'
							caption='join waitlist'
							onClick={onTogglePopup}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
