import React from "react";

import "./button-custom.scss";

const Button = ({ title, onClick, caption }) => {
	const buttonText = caption != null ? caption : title.replace(/-/g, " ");

	return (
		<button className={`button-custom ${title}`} onClick={onClick}>
			<span>{buttonText}</span>
		</button>
	);
};

export default Button;
